<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <master-golongan/>
            </template>
            <template v-if="menuActive === 2">
              <master-jabatan/>
            </template>
            <template v-if="menuActive === 3">
              <master-marital/>
            </template>
            <template v-if="menuActive === 4">
              <insentif/>
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
const Menu = () => import('@/components/app/MenuV2')
const MasterGolongan = () => import('@/views/AdminKeuangan/MasterData/Golongan')
const MasterJabatan = () => import('@/views/AdminKeuangan/MasterData/Jabatan')
const MasterMarital = () => import('@/views/AdminKeuangan/MasterData/Marital')
const Insentif = () => import('@/views/AdminKeuangan/MasterData/Insentif')

const menus = [
  {
    key: 1,
    menu: 'Golongan',
  },
  {
    key: 2,
    menu: 'Jabatan & Tunjangan',
  },
  {
    key: 3,
    menu: 'Marital & Tunjangan',
  },
  {
    key: 4,
    menu: 'Insentif',
  },
]

export default {
  name: 'MasterData',
  components: {
    Menu,
    MasterGolongan,
    MasterJabatan,
    MasterMarital,
    Insentif,
  },
  data() {
    return {
      menus,
      menuActive: 1,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
}
</script>

<style lang="css">
</style>
