<template>
  <div>
    <a-row :gutter="16">
      <a-col :span="5">
        <div class="card">
          <div class="card-header card-header-flex">
             <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">Menu</h2>
              </div>
            </div>
          </div>
          <div class="card-body">
            <Menu :menus="menus" :menuActive="menuActive" @menu-change="menuChange" />
          </div>
        </div>
      </a-col>
      <a-col :span="19">
        <div class="card">
          <div class="card-header card-header-flex">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="font-size-18">{{menuName}}</h2>
              </div>
            </div>
          </div>
          <div class="card-body mt-3">
            <template v-if="menuActive === 1">
              <master-unit/>
            </template>
            <template v-if="menuActive === 2">
              <master-golongan/>
            </template>
            <template v-if="menuActive === 3">
              <master-jabatan/>
            </template>
            <template v-if="menuActive === 4">
              <master-marital/>
            </template>
            <template v-if="menuActive === 5">
              <master-pegawai/>
            </template>
            <template v-if="menuActive === 6">
              <shift />
            </template>
            <template v-if="menuActive === 7">
              <kegiatan />
            </template>
            <template v-if="menuActive === 8">
              <cuti />
            </template>
            <template v-if="menuActive === 9">
              <izin />
            </template>
            <template v-if="menuActive === 10">
              <insentif-pegawai />
            </template>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import thousandPrice from '@/helpers/thousandPrice'
const Menu = () => import('@/components/app/MenuV2')
const MasterUnit = () => import('@/views/AdminHR/MasterData/Unit')
const MasterGolongan = () => import('@/views/AdminHR/MasterData/Golongan')
const MasterJabatan = () => import('@/views/AdminHR/MasterData/Jabatan')
const MasterMarital = () => import('@/views/AdminHR/MasterData/Marital')
const MasterPegawai = () => import('@/views/AdminHR/MasterData/Pegawai')
const Shift = () => import('@/views/AdminHR/MasterData/Shift')
const Kegiatan = () => import('@/views/AdminHR/MasterData/Kegiatan')
const Cuti = () => import('@/views/AdminHR/MasterData/Cuti')
const Izin = () => import('@/views/AdminHR/MasterData/Izin')
const InsentifPegawai = () => import('@/views/AdminHR/MasterData/InsentifPegawai')

const menus = [
  {
    key: 1,
    menu: 'Unit',
  },
  {
    key: 2,
    menu: 'Golongan',
  },
  {
    key: 3,
    menu: 'Jabatan & Tunjangan',
  },
  {
    key: 4,
    menu: 'Marital & Tunjangan',
  },
  {
    key: 5,
    menu: 'Pegawai',
  },
  {
    key: 6,
    menu: 'Shift',
  },
  {
    key: 7,
    menu: 'Kegiatan',
  },
  {
    key: 8,
    menu: 'Cuti',
  },
  {
    key: 9,
    menu: 'Izin',
  },
  {
    key: 10,
    menu: 'Insentif Pegawai',
  },
]

export default {
  name: 'MasterData',
  components: {
    Menu,
    MasterUnit,
    MasterGolongan,
    MasterJabatan,
    MasterMarital,
    MasterPegawai,
    Shift,
    Kegiatan,
    Cuti,
    Izin,
    InsentifPegawai,
  },
  data() {
    return {
      menus,
      menuActive: 10,
      thousandPrice,
    }
  },
  methods: {
    menuChange(payload) {
      this.menuActive = payload
    },
  },
  computed: {
    menuName() {
      const { menu } = this.menus.filter(el => el.key === this.menuActive)[0]
      return menu
    },
  },
}
</script>

<style lang="css">
</style>
